import React, { useState, useEffect, useRef } from "react";

import logo from "../../assets/logo.svg";
import logo1 from "../../assets/logo1.svg";
import logo2 from "../../assets/logo2.svg";

const Logo = () => {
  const [currentImg, setCurrentImg] = useState(0);
  const intervalRef = useRef(null);
  const images = [logo, logo1, logo2];

  const startAnimation = () => {
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        setCurrentImg((prev) => (prev + 1) % images.length);
      }, 400);
    }
  };

  const stopAnimation = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      setCurrentImg(0); // Reset to initial logo
    }
  };

  useEffect(() => {
    // Cleanup interval on component unmount
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <img
      src={images[currentImg]}
      onMouseEnter={startAnimation}
      onMouseLeave={stopAnimation}
    />
  );
};

export default Logo;
